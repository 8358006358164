import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProjectItem from './ProjectItem';
import './Projects.css';

const projectsData = [
  {
    image: '/project-list1.jpg',
    category: 'Design, Branding',
    title: 'One Cotroceni Park',
    description: 'One Cotroceni Park redefinește standardele de inovație și sustenabilitate, oferind un spațiu de birouri modern și eficient, conceput pentru a inspira și a sprijini dezvoltarea afacerilor într-un mediu dinamic și colaborativ.',
    imageFirst: true
  },
  {
    image: '/project-list2.jpg',
    category: 'Design, Branding',
    title: 'United Business Center Timișoara',
    description: 'United Business Center Timișoara oferă un spațiu de birouri modern și elegant, proiectat să îndeplinească cele mai înalte standarde de sustenabilitate și eficiență, contribuind la crearea unui mediu de afaceri inovator și productiv.',
    imageFirst: false
  },
  {
    image: '/project-list3.jpg',
    category: 'Design, Branding',
    title: 'Hotel Radisson Blu Aurum Brașov',
    description: 'Hotelul Radisson Blu Aurum Brașov îmbină eleganța modernă cu farmecul istoric al orașului, oferind o experiență de lux în inima Brașovului.',
    imageFirst: true
  },
  {
    image: '/project-list4.jpg',
    category: 'Design, Branding',
    title: 'Promenada Sibiu',
    description: 'Promenada Sibiu oferă o experiență unică de shopping și recreere, combinând un design modern cu un spațiu vast pentru retail, restaurante și zone verzi.',
    imageFirst: false
  },
  {
    image: '/project-list5.jpg',
    category: 'Design, Branding',
    title: 'H&M Park Lake',
    description: 'H&M Park Lake aduce stilul și accesibilitatea într-un spațiu modern și atrăgător, oferind o experiență de shopping confortabilă.',
    imageFirst: true
  },
  {
    image: '/project-list6.jpg',
    category: 'Design, Branding',
    title: 'Cinema City Suceava',
    description: 'Cinema City Suceava îți oferă cea mai bună experiență cinematografică, combinând cele mai recente tehnologii de proiecție și sunet cu un ambient modern și primitor.',
    imageFirst: false
  }
];

function Projects() {
  const [visibleProjects, setVisibleProjects] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true
    });
  }, []);

  const handleViewMore = async () => {
    setIsLoading(true);
    try {
      // Simulate API call with setTimeout
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      if (visibleProjects + 3 >= projectsData.length) {
        setHasMore(false);
      }
      
      setVisibleProjects(prevCount => 
        prevCount + 3 <= projectsData.length ? prevCount + 3 : projectsData.length
      );
    } catch (error) {
      console.error('Error loading more projects:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="projects-section">
      <h2 className="section-title">
        Redefinim viitorul construcțiilor cu inovație și precizie.
      </h2>
      <div className="projects-container">
        {projectsData.slice(0, visibleProjects).map((project, index) => (
          <ProjectItem key={index} {...project} data-aos="fade-right" />
        ))}
      </div>
      <button 
        className={`view-more-btn ${!hasMore ? 'no-more' : ''} ${isLoading ? 'loading' : ''}`}
        onClick={handleViewMore}
        disabled={isLoading || !hasMore}
      >
        {isLoading ? 'Se încarcă...' : hasMore ? 'Vezi mai multe proiecte' : 'Nu mai sunt proiecte'}
      </button>
    </section>
  );
}

export default Projects;