import React from 'react';
import './Footer.css';

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE AVATTI INDUSTRIES SRL.pdf";

function Footer() {

  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-logo">
            <img src="  avatti-logo.png" alt="AVATTI" />
            <p>Digital Business Consulting Agency</p>
          </div>
          <div className="newsletter">
            <h3>Subscribe Our Newsletter</h3>
            <form className="newsletter-form">
              <input type="email" placeholder="Email Address" required />
              <button type="submit">Sign Up</button>
            </form>
          </div>
        </div>

        <div className="footer-info">
          <div className="contact-info">
            <h4>Contact</h4>
            <ul>
              <li><p>Punct de lucru:</p> Jud. BUCURESTI, loc. BUCURESTI SECTORUL 2, sect. 2, str. RADU DE LA AFUMATI, nr. 72</li>
              <li><a href="mailto:gabriela.nica@avatti.ro">gabriela.nica@avatti.ro</a></li>
              <li><a href="tel:0731367222">0731367222</a></li>
            </ul>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61569484533456" target="https://www.facebook.com/profile.php?id=61569484533456" rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.linkedin.com/in/avatti-industries-750b7633a/" target="https://www.linkedin.com/in/avatti-industries-750b7633a/" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

         
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.7443148815796!2d26.1162841!3d44.4502044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff3d3b4c473d%3A0x0!2sJud.%20BUCURESTI%2C%20loc.%20BUCURESTI%20SECTORUL%202%2C%20sect.%202%2C%20str.%20RADU%20DE%20LA%20AFUMATI%2C%20nr.%2072!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Office Location"
            />
          </div>
        </div>

        <div className="eu-funding-section">
        <div className="container">
          <div className="funding-content">
            <p className="funding-text">
              Pentru informații detaliate despre celelalte programe cofinanțate
              de Uniunea Europeană, vă invităm să vizitați{" "}
              <a href="https://www.mfe.gov.ro" className="funding-link">
                www.mfe.gov.ro
              </a>
            </p>
            <div className="links-and-button">
              <div className="funding-links">
                <a href="http://www.regionordest.ro" className="funding-link">
                  www.regionordest.ro
                </a>
                <a
                  href="https://www.facebook.com/Regio.NordEst.ro"
                  className="funding-link"
                >
                  www.facebook.com/Regio.NordEst.ro
                </a>
              </div>
              <div className="aproape-button">Aproape de tine</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-banner">
            <div className="logo-row">
              <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
              <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
              <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
              <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
            </div>
            <button onClick={handleInfoClick} className="info-button">
              Proiect digitalizare - Află mai multe
            </button>
          </div>

        <div className="footer-bottom">
          <p>&copy; 2024 <a href="index.html">AVATTI INDUSTRIES</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;