import React from 'react';

function ProjectItem({ image, category, title, description, imageFirst, ...props }) {
  return (
    <div className="project-item" {...props}>
      {imageFirst ? (
        <>
          <div className="project-image">
            <img src={image} alt={title} />
          </div>
          <div className="project-content">
            <span className="project-category">{category}</span>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </>
      ) : (
        <>
          <div className="project-content">
            <span className="project-category">{category}</span>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="project-image">
            <img src={image} alt={title} />
          </div>
        </>
      )}
    </div>
  );
}

export default ProjectItem;