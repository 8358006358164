import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Footer from './components/Footer';
import FloatingContact from './components/FloatingContact';
import ChatSystem from './components/ChatSystem';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="spline-container">
        <iframe
          id="spline-viewer"
          src="https://my.spline.design/clonercubesgenerativecopy-40d7a322b1c7119d99e0a56275003dff/"
          frameBorder="0"
          title="spline-viewer"
        />
      </div>
      <Header />
      <Hero />
      <Projects />
      <Footer />
      <FloatingContact />
      <ChatSystem />
    </div>
  );
}

export default App;