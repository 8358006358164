import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <div className="animated-text">
          <div className="text-wrapper">
            <div className="keyboard-text">
              {['A', 'V', 'A', 'T', 'T', 'I'].map((letter, index) => (
                <span key={index} className="letter">{letter}</span>
              ))}
            </div>
            <div className="years-badge">30 YEARS OF BUILDING TRUST</div>
          </div>
        </div>
        <div className="project-info">
          <p><strong>Lider</strong> în oferirea de soluții integrate pentru construcții
          și inginerie, operând cu un nivel înalt de profesionalism și dedicare.
          Suntem partenerul de încredere pentru orice proiect, garantând
          durabilitate, eficiență și inovație.</p>
          
        </div>
      </div>
    </section>
  );
}

export default Hero;