import React, { useState, useEffect } from 'react';
import './Header.css';

function Header() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // Show header when scrolling up or at top
      if (currentScrollY < lastScrollY || currentScrollY < 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <header className={`main-header ${!isVisible ? 'hidden' : ''} ${lastScrollY > 100 ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo-group">
          <img src="/avatti-logo.png" alt="AVATTI" />
        </div>
        <a href="https://dashboard.avati.ro" className="login-btn">LOGIN</a>
      </div>
    </header>
  );
}

export default Header;